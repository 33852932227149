import React from "react"
import styled from 'styled-components'
import Layout from "../components/layout"
import { Link } from 'gatsby'
import SEO from "../components/seo"
import SuccessCheck from "../components/Icons/SuccessCheck"
import DefaultButton from "../components/button/DefaultButton"

const Wrapper = styled.div`
	text-align: center;
	padding: 120px 0 60px;
	@media (min-width: 768px){
		padding: 160px 60px;
	}
	@media (min-width: 992px){
		padding: 200px 100px;
	}
	@media (min-width: 1400px){
		padding: 300px 100px;
	}
	& h1 {
		font-size: 34px;
	}
	& p {
		max-width: 960px;
		width:100%;
		margin: 16px auto 30px;
	}
	& .btn {
		width: 150px;
	}
`

const Thanks = ({ data, location }) => (
  <Layout location={location}>
    <SEO title="thanks" />
    <Wrapper>
	    <div className="container">
		    <SuccessCheck />
		    <h1>Thank you for connecting with us </h1>
		    <p> 
		    	Thank you for your interest in Premier Metal Structures with your metal building needs! We'll certainly get back to you with more details.
		    </p>
		    <Link to="/">
		    	<DefaultButton text="Home" />
		    </Link>
	  	</div>
	  </Wrapper>
  </Layout>
)

export default Thanks
