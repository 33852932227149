import React from 'react'

const SuccessCheck = () => {
	return(
		<svg xmlns="http://www.w3.org/2000/svg" width="128.893" height="128.894" viewBox="0 0 128.893 128.894">
		  <defs>
		    <clipPath id="clip-path">
		      <path id="Path_17699" data-name="Path 17699" d="M187,251.45a64.446,64.446,0,1,1,45.571-18.876A64.021,64.021,0,0,1,187,251.45ZM144.965,187c0,35.089,92.467,35.089,92.467,0S144.965,151.914,144.965,187Z" transform="translate(613.004 61.38)" fill="#666"/>
		    </clipPath>
		  </defs>
		  <g id="Group_6733" data-name="Group 6733" transform="translate(-735.562 -183.936)">
		    <g id="Mask_Group_2" data-name="Mask Group 2" clip-path="url(#clip-path)">
		      <g id="Group_6732" data-name="Group 6732" transform="translate(638 86.373)">
		        <g id="Group_6731" data-name="Group 6731" transform="translate(90 90)">
		          <path id="Path_17696" data-name="Path 17696" d="M187,251.45a64.446,64.446,0,1,1,45.571-18.876A64.021,64.021,0,0,1,187,251.45ZM144.965,187c0,35.089,92.467,35.089,92.467,0S144.965,151.914,144.965,187Z" transform="translate(-114.995 -114.994)" fill="#666"/>
		          <rect id="Rectangle_2773" data-name="Rectangle 2773" width="87" height="152" transform="translate(65 -5.373)" fill="#999" opacity="0.45"/>
		        </g>
		        <path id="Path_17697" data-name="Path 17697" d="M222.362,276.1a53.752,53.752,0,1,1,53.749-53.756A53.814,53.814,0,0,1,222.362,276.1Z" transform="translate(-60.353 -60.341)" fill="#fff"/>
		      </g>
		    </g>
		    <path id="Path_17700" data-name="Path 17700" d="M316.791,272.069a6.939,6.939,0,0,0-9.07-3.746c-9.981,4.146-20.794,14.06-32.139,29.467-3.246,4.407-6.057,8.621-8.281,12.123-4.175-5.74-8.17-11.266-9-12.511a6.939,6.939,0,1,0-11.548,7.7c1.771,2.656,13.387,18.554,15.358,21.251a7.019,7.019,0,0,0,.51.7,6.847,6.847,0,0,0,11.2-1l.138-.252,0-.009c5.6-10.237,23.533-38.19,39.084-44.65A6.94,6.94,0,0,0,316.791,272.069Z" transform="translate(518.555 -50.118)" fill="#00a21e"/>
		  </g>
		</svg>

	)
}

export default SuccessCheck